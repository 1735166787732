import { Maybe, OptionItem } from '@/common/types';
import { gql } from '@apollo/client';
import { IHierarchicalProduct, IProduct } from '../types';
import type { ProductNameUtil_ProductFragment } from './index.generated';

gql`
fragment ProductNameUtil_Product on Product {
  parentProduct {
      id
      name
      parentProduct {
          id
          name
          parentProduct {
              id
              name
          }
      }
  }
}
`;

export const formatParentProductName = (product: ProductNameUtil_ProductFragment): string => {
  return [
    product.parentProduct?.parentProduct?.parentProduct?.name,
    product.parentProduct?.parentProduct?.name,
    product.parentProduct?.name,
  ]
    .filter((value) => value)
    .join(' > ');
};

export const formatProductName = (product: Maybe<IProduct>): string => {
  if (!product) return '';
  return product.parentProduct
    ? `${formatProductName(product.parentProduct)} > ${product.name}`
    : product.name;
};

export const getProductNameList = (product: Maybe<IProduct>, nameList: string[] = []): string[] => {
  if (!product) return nameList;
  const output = [product.name, ...nameList];
  return product.parentProduct ? getProductNameList(product.parentProduct, output) : output;
};

export const adjustProductsForSelect = (product: IHierarchicalProduct): OptionItem => {
  const { id, name: label, childProducts: children } = product;

  if (children && children.length > 0) {
    return {
      id,
      label,
      children: children.map(adjustProductsForSelect),
    };
  }
  return {
    id,
    label,
  };
};

export const filterProductsForSelect = (
  targetProductId: number,
  selectProducts: OptionItem[]
): OptionItem[] => {
  const filteredSelectProducts = selectProducts.reduce<OptionItem[]>((acc, product) => {
    if (product.id !== targetProductId) {
      const filteredProduct = { ...product };
      if (product.children) {
        filteredProduct.children = filterProductsForSelect(targetProductId, product.children);
      }
      acc.push(filteredProduct);
    }
    return acc;
  }, []);
  return filteredSelectProducts;
};

export const hasChildrenProducts = (productId: number, products: OptionItem[]): boolean => {
  for (const product of products) {
    if (product.id === productId) {
      // 商品IDが一致した場合、その商品が子商品を持っているかどうかを返す
      return !!product.children && product.children.length > 0;
    }
    const result = hasChildrenProducts(productId, product.children || []);
    // 子商品を持っている場合、trueを返す
    if (result) return true;
  }
  return false;
};
